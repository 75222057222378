.share-profile-button {
  opacity: 0.2;
}

.share-profile-button:hover {
  opacity: 1;
}

.sweetsheet-button {
  opacity: 0.2;
}

.sweetsheet-button:hover {
  opacity: 1;
}


.nearly-transparent-button {
  opacity: 0.2;
}

.nearly-transparent-button:hover {
  opacity: 1;
}


.actionsContainer {
  position: absolute;
  margin-left: 39vw;
  bottom: 10vh;
  opacity: 0.2;
  z-index: 1;
  text-align: center;
}

.actionsContainer:hover {
  opacity: 1;
}

.nextProfilesContainer {
  position: absolute;
  padding: 1em;
  left: 2em;
  top: 5em;
  width: 30vw;
  height: 80vh;
  z-index: 1;
}

platformView {
  padding-left: 22px;
}
.platformView h1 {
  font-size: 20px;
  margin-bottom: 5px;
}
.platformView .item {
  padding-left: 23px;
  margin-bottom: 2px;
  font-size: 13px;
}
.platformView .item .emailSection {
  background: none;
  outline: none;
  border: none;
  font-size: 11px;
}
.platformView .item .emailSection span {
  color: blue;
  font-style: underline;
}
.platformView .action {
  padding-left: 20px;
}
.platformView .action .skipProfile {
  color: red;
}
.platformView .action .disqualifyProfile {
  color: red;
}
.platformView .action .send {
  color: blue;
}
.platformView .action .send-follow-up {
  color: cyan;
}
.platformView .action .save-annotation {
  color: orange;
}
.platformView .action .answer-detected {
  color: green;
}
.platformView .action .answer-detected.admin {
  cursor: pointer;
}
.platformView .action .move-to-process {
  color: green;
}
.platformView .action .move-to-hired {
  color: purple;
}
.platformView .action .date {
  color: black;
}


.miller-columns {
  display: flex;
  flex-flow: row nowrap;
  > * {
    flex: 0 1;
  }
}


.regression-detail pre {
		width: 50vh;
	}
.regression-detail .d2h-diff-tbody>tr>td {
	font-size: 0.7em;
	line-height: 0.9em;
	height: 0.9em;
}

.d2h-file-header {
	display: none;
}

.regressions-selection .dates .item {
	flex-direction: column;
}
.regressions-selection .dates .item .label {
	margin-top: 10px;
}

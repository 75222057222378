/* Source */
@font-face {
  font-family: sourceSansPro;
  src: url("/fonts/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: sourceSansProSemiBold;
  src: url("/fonts/SourceSansPro-SemiBold.ttf");
}
.collapsible-toggle {
  padding-left: 3px;
  color: black;
  cursor: pointer;
  text-decoration: underline;
}
.collapsible-toggle:hover {
  color: #0087d5;
}
.loading-component {
  background: linear-gradient(90deg, #ececec, #cccaca, #ececec);
  background-size: 200% 100%;
  border-radius: 2px;
  animation: Gradient 5s linear infinite;
  display: block;
  height: 0.7rem;
  margin: 0.5rem 0;
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.profile-resume-container {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 25px;
  margin-bottom: 150px !important;
}
.profile-resume-container .row,
.profile-resume-container .wide.column,
.profile-resume-container .ui-grid > * {
  padding: 0px 0px 0px 0px !important;
}
.profile-resume-container .resume-data-card {
  font-family: sourceSansPro;
}
.profile-resume-container .resume-data-card .profile-header {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 30px !important;
}
.profile-resume-container .resume-data-card .profile-header .contacts .contact-link {
  margin-left: 10px !important;
}
.profile-resume-container .resume-data-card .profile-header .contacts .contact-link .contact-link-inner img {
  max-height: 13px;
  max-width: 13px;
  opacity: 0.35;
}
.profile-resume-container .resume-data-card .profile-header .contacts .contact-link .contact-link-inner img:hover {
  opacity: 1;
}
.profile-resume-container .resume-data-card .profile-header .contacts .contact-link .contact-link-inner span {
  color: black;
  opacity: 0.35;
}
.profile-resume-container .resume-data-card .profile-header .contacts .contact-link .contact-link-inner span:hover {
  color: #4183c4;
  opacity: 1;
  text-decoration: underline;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos {
  display: flex;
  flex-flow: row nowrap;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos .step-modification-view {
  position: absolute;
  top: 0px;
  right: 0px;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos .avatar {
  position: relative;
  margin-right: 20px !important;
  min-width: 90px !important;
  min-height: 90px !important;
  width: 90px !important;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos .avatar img {
  width: 86px;
  height: 86px;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos .infos {
  flex: 1 0;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos .infos .fullname {
  font-family: sourceSansProSemiBold;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 21px;
  color: #222222;
  letter-spacing: 0;
  line-height: 32px;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos .infos .headline {
  margin: 0;
  padding: 0;
  font-family: sourceSansPro;
  font-weight: normal;
  font-size: 14px;
  color: #222222;
  line-height: 22px;
}
.profile-resume-container .resume-data-card .profile-header .avatar-and-infos .infos .main-education {
  margin: 0;
  padding: 0;
  opacity: 0.5;
  font-family: sourceSansPro;
  font-weight: normal;
  font-size: 14px;
  color: #222222;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .resume-data-card .profile-summary {
  margin-bottom: 22px !important;
}
.profile-resume-container .resume-data-card .profile-relevant-tags .relevant-tag-pill {
  display: inline;
  margin-right: 11px;
  margin-bottom: 8px;
  height: 32px;
  background: #f0f0f0;
  border-radius: 3px;
  line-height: 32px;
}
.profile-resume-container .resume-data-card .profile-relevant-tags .relevant-tag-pill .relevant-tag-pill-inner {
  font-family: sourceSansPro;
  font-size: 13px;
  color: #1d1e21;
  letter-spacing: 0;
  text-align: center;
}
.profile-resume-container .resume-data-card .profile-relevant-tags .relevant-tag-pill .relevant-tag-pill-inner .tag-label {
  font-weight: bold;
}
.profile-resume-container .resume-data-card .profile-relevant-tags .relevant-tag-pill .relevant-tag-pill-inner .tag-content {
  font-weight: normal;
}
.profile-resume-container .background-section-title {
  margin: 35px 0px 27px 0px !important;
  font-family: sourceSansProSemiBold;
  font-weight: bold !important;
  font-size: 13px !important;
  color: #0087d5 !important;
  letter-spacing: 0px !important;
}
.profile-resume-container .github-extract h4 {
  margin-bottom: 8px;
  font-family: sourceSansProSemibold;
  font-size: 14px;
  color: #1d1e21;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .github-extract .repository-card {
  border: 1px solid #e7e9eb !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 16px !important;
}
.profile-resume-container .github-extract .repository-card .repo-stats {
  margin: 0;
}
.profile-resume-container .github-extract .repository-card .repo-stats .stat-item {
  margin-right: 12px;
}
.profile-resume-container .github-extract .repository-card .repo-stats .stat-item .text {
  font-family: sourceSansPro;
  font-size: 14px;
  color: #656873;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .github-extract .repository-card .repo-stats .language {
  margin-right: 12px;
}
.profile-resume-container .github-extract .repository-card .repo-stats .language .text {
  font-family: sourceSansPro;
  font-size: 14px;
  color: #656873;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .github-extract .repository-card .repo-stats .stars {
  margin-right: 12px;
}
.profile-resume-container .github-extract .repository-card .repo-stats .stars .text {
  font-family: sourceSansPro;
  font-size: 14px;
  color: #656873;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .github-extract .repository-card .repo-stats .forks {
  margin-right: 12px;
}
.profile-resume-container .github-extract .repository-card .repo-stats .forks .text {
  font-family: sourceSansPro;
  font-size: 14px;
  color: #656873;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .github-extract .repository-card .repo-title {
  margin: 0;
}
.profile-resume-container .github-extract .repository-card .repo-title a.repo-link {
  font-family: sourceSansProSemibold;
  font-size: 16px;
  color: #0087d5;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .github-extract .repository-card .description {
  font-family: sourceSansPro;
  font-size: 14px;
  color: #656873;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .linkedin-extract {
  margin-bottom: 20px;
}
.profile-resume-container .linkedin-extract .skill-tags-container h4 {
  margin-bottom: 0px;
  font-family: sourceSansProSemibold;
  font-size: 14px;
  color: #1d1e21;
  letter-spacing: 0;
  line-height: 22px;
}
.profile-resume-container .linkedin-extract .skill-tags-container .skill-tags .skill-tag {
  margin-right: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 0px !important;
  border-radius: 3px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-family: sourceSansProSemibold;
  font-size: 13px;
  color: #1d1e21;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
}
.profile-resume-container .linkedin-extract .skill-tags-container .skill-tags .skill-tag.normal {
  background: #e8e8e8;
  opacity: 0.7;
  color: #1d1e21;
}
.profile-resume-container .linkedin-extract .skill-tags-container .skill-tags .skill-tag.important {
  background: #0087d5;
  color: white;
}
.profile-resume-container .stackoverflow-extract {
  margin-bottom: 20px;
}
.profile-resume-container .stackoverflow-extract h4 {
  margin-bottom: 8px;
  font-family: sourceSansProSemibold;
  font-size: 14px;
  color: #1d1e21;
  letter-spacing: 0;
  line-height: 22px;
}
.relevant-facts .relevant-fact-container {
  background: #ffffff;
  border: 1px solid #e7e9eb;
}
.relevant-facts .relevant-fact-container.not-first-row {
  border-top: none !important;
}
.relevant-facts .relevant-fact-container.not-first-column {
  border-left: none !important;
}
.relevant-facts .relevant-fact-container .relevant-fact {
  padding: 15px;
}
.relevant-facts .relevant-fact-container .relevant-fact .value {
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  font-family: sourceSansProSemibold;
  color: #0087d5;
}
.relevant-facts .relevant-fact-container .relevant-fact .label {
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0 !important;
  font-family: sourceSansPro;
  font-weight: normal;
  text-transform: none !important;
  color: #656873;
}
.background-item-card {
  border-style: solid;
  border-width: 1px;
  margin-bottom: 7px !important;
  padding: 15px 15px 12px 0px !important;
  transition: border-color 0.2s ease;
  display: flex;
  flex-flow: row nowrap;
  border-color: transparent;
}
.background-item-card > .gutter {
  width: 40px;
  margin-right: 11px;
}
.background-item-card > .main {
  flex: 1 0;
}
.background-item-card.expandable {
  cursor: pointer;
}
.background-item-card .card-content {
  padding-top: 12px;
  height: 0;
  transition: height 0.1s ease;
  overflow: hidden;
  font-family: sourceSansPro;
  font-size: 12px;
  color: #1d1e21;
  letter-spacing: 0;
  line-height: 22px;
}
.background-item-card .card-header .show-more {
  float: right;
}
.background-item-card .card-header .card-title {
  font-family: sourceSansProSemibold;
  font-size: 16px;
  color: #1d1e21;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
}
.background-item-card .card-header .card-title .card-link {
  cursor: pointer;
  text-decoration: none;
}
.background-item-card .card-header .card-title .card-link:hover {
  text-decoration: underline;
}
.background-item-card .card-header .card-title .card-link:not(:hover) {
  color: #1d1e21;
}
.background-item-card .card-header .card-title .workplace-popup-trigger {
  text-decoration: underline;
}
.background-item-card .card-header .card-title .workplace-popup-trigger:hover {
  color: #0087D5;
}
.background-item-card .card-header .card-title .workplace-popup-trigger a:hover {
  color: #0087D5;
}
.background-item-card .card-header .card-subtitle {
  opacity: 0.5;
  font-family: sourceSansPro;
  font-size: 14px;
  color: #222222;
  letter-spacing: 0;
  line-height: 22px;
}
.background-item-card .card-header .card-caret {
  opacity: 0.4;
  cursor: pointer;
}
.background-item-card .card-header .technology-icon {
  max-width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle !important;
}
.background-item-card .card-header .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}
.background-item-card .card-header .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  font-family: sourceSansPro;
  padding-right: 2px;
  padding-left: 2px;
}
.background-item-card .card-company-image {
  margin-left: 10px;
  background: #b3b6b9;
  border-radius: 2px;
  width: 36px;
  height: 36px;
  margin-right: 11px;
  object-fit: contain;
}
.background-item-card.expanded .card-content {
  height: 100%;
}
.background-item-card.expandable:hover {
  border-color: #e7e9eb;
}
.background-item-card.no-collapse.expanded {
  border-color: transparent;
}
.background-toggle {
  opacity: 0.5;
  font-family: sourceSansPro;
  font-size: 14px;
  color: #222222;
  letter-spacing: 0;
  line-height: 22px;
  cursor: pointer;
}
.background-toggle:hover {
  text-decoration: underline;
}
.background-toggle.down:after {
  content: '';
  background-image: url('/images/icons/dark-down-caret.png');
  background-size: 8px 5px;
  width: 8px;
  height: 5px;
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
  opacity: 1;
  display: inline-block;
}
.background-toggle.up:after {
  content: '';
  background-image: url('/images/icons/dark-up-caret.png');
  background-size: 8px 5px;
  width: 8px;
  height: 5px;
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
  opacity: 1;
  display: inline-block;
}
.workplace-popup {
  border: none !important;
  min-width: 370px !important;
  font-size: 14px !important;
  font-family: sourceSansPro;
}
.workplace-popup .popup-first-line {
  height: 20px;
}
.workplace-popup .popup-first-line .popup-title {
  float: left;
  font-size: 16px;
  color: #1d1e21;
  font-family: sourceSansProSemiBold;
}
.workplace-popup .popup-subtitle {
  color: #222222;
  font-size: 14px;
}
.workplace-popup .popup-subtitle > span {
  opacity: 0.5;
}
.workplace-popup .popup-subtitle .workplace-staff-count {
  float: right;
  font-size: 14px;
  color: #1d1e21;
}
.workplace-popup .popup-subtitle {
  color: #222222;
  font-size: 14px;
}
.workplace-popup .popup-subtitle > span {
  opacity: 0.5;
}
.workplace-popup .popup-subtitle .workplace-staff-count {
  float: right;
  font-size: 14px;
  color: #1d1e21;
}
.workplace-popup .workplace-industries {
  margin-top: 6px;
  margin-bottom: 3px;
}
.workplace-popup .workplace-industries .industry-label {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 3px;
  margin-left: 0px;
}
.workplace-popup .workplace-stack {
  margin-top: 6px;
  line-height: 20px;
}
.workplace-popup .workplace-stack .technology-icon {
  max-width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle !important;
}
.workplace-popup .workplace-stack .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 20px;
  width: 20px;
}
.workplace-popup .workplace-stack .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 20px;
  font-family: sourceSansPro;
  padding-right: 2px;
  padding-left: 2px;
}
.workplace-popup .workplace-description {
  margin-top: 8px;
}
.workplace-popup .sources-container {
  height: 20px;
  float: right;
  display: flex;
  flex-direction: row;
}
.workplace-popup .sources-container .source-link {
  margin-right: 6px;
}
.workplace-popup .sources-container .source-link:last-child {
  margin-right: 0px;
}
.workplace-popup .sources-container .source-link img {
  height: 16px;
  width: 16px;
  opacity: 0.35;
}
.workplace-popup .sources-container .source-link img:hover {
  opacity: 1;
}
.summary-tag-stack-container .stack-container {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}
.summary-tag-stack-container .stack-container .summary-title {
  font-family: sourceSansProSemibold;
  font-size: 16px;
  line-height: 26px;
}
.summary-tag-stack-container .stack-container .summary-title .summary-subtitle {
  font-size: 14px;
  font-family: sourceSansPro;
}
.summary-tag-stack-container .stack-container .stack .technology-icon {
  cursor: pointer;
  display: inline-block;
  max-width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle !important;
}
.summary-tag-stack-container .stack-container .stack .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}
.summary-tag-stack-container .stack-container .stack .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  font-family: sourceSansPro;
  padding-right: 2px;
  padding-left: 2px;
}
.summary-tag-stack-container .profile-summary {
  margin-bottom: 10px !important;
  font-family: sourceSansPro;
  font-size: 14px;
  line-height: 22px;
}
.skill-popup .skill-name {
  font-size: 14px;
  font-family: sourceSansProSemiBold;
}
.skill-popup .skill-description {
  margin-top: 6px;
  font-family: sourceSansPro;
  font-size: 12px;
  line-height: 16px;
}
.skill-popup .has-details {
  min-width: 220px;
}

.row {
  display: flex;
  flex-direction: row;
}

.avatar {
  width: 40px;
  max-height: 40px;
}

.experience-container {
  position: relative;
}
.experience-container .stack-container {
  position: absolute;
  top: 0;
  right: 0;
}
.experience-container .stack-container .technology-icon {
  max-width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle !important;
}

.experience-container .stack-container .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}

.experience-container .stack-container .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  font-family: sourceSansPro;
  padding-right: 2px;
  padding-left: 2px;
}

.education-container {
  position: relative;
}
.education-container .stack-container {
  position: absolute;
  top: 0;
  right: 0;
}
.education-container .stack-container .technology-icon {
  max-width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle !important;
}

.education-container .stack-container .technology-icon .initials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  height: 18px;
  width: 18px;
}

.education-container .stack-container .technology-icon .initials-container .initials {
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  font-family: sourceSansPro;
  padding-right: 2px;
  padding-left: 2px;
}
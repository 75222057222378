@font-face {
  font-family: sourceSansPro;
  src: url("/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: sourceSansProSemiBold;
  src: url("/fonts/SourceSansPro-SemiBold.ttf");
}

.skill-tag {
  cursor: pointer;
}

.skill-tag.normal {
  background: #eeeff5;
  opacity: 0.7;
  color: #1d1e21;
}

.skill-tag.important {
  background-color: #5C89EB;
  color: white;
}

.skill-tag.medium {
  background: #8DB1FF;
  color: white;
}

.ui.label.skill-tag {
  margin-left: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  height: 22px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 22px;
}

input {
  outline: none;
  border-radius: 2px;
  border: solid #0087d5 1px;
  padding: 1px;
  font-family: sourceSansPro;
}

.text-input {
  cursor: pointer;
}

.relevant-tag {
  display: inline-block;
  cursor: pointer;
  background: #f0f0f0;
  color: #1d1e21;
  text-align: center;
  padding-right: 0.5em;
  padding-left: 0.5em;
  margin-right: 11px;
  margin-bottom: 8px;
  border-radius: 3px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.relevant-tag.tag-content {
  font-weight: normal;
}

.relevant-tag.tag-label {
  font-weight: bold;
}

.collapsible-content {
  position: relative;
  overflow: hidden;
}

.collapsible-content .collapsible-bottom-gradient {
  position: absolute;
  left: 0;
  width: 100%;
  height: 15px;
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C */
}

.ui.progress .bar {
  min-width: 0px;
}

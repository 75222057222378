.table-header-descending {
  padding: 0px 15px !important;
}
.table-header-descending > .caret {
    position: relative;
    cursor: pointer;
    /* margin-right: 15px !important; */
}

.table-header-descending > .caret:after {
  content: '▲';
  position: absolute;
}

.table-header-ascending > .caret:after{
  content: '▼';
  position: absolute;
}

.table-header-ascending {
  padding: 0px 15px !important;
}